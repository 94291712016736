<template>
  <master-layout>
    <div>
      <!-- Start Add Event Design -->
      <v-card class="add-event-card pa-5">
        <validation-observer ref="FormObserver" v-slot="{ handleSubmit }">
          <v-form
            class="newClass"
            @submit.prevent="
              handleSubmit(!queryParms ? addNewEvent : updateNewEvent)
            "
            ref="AddUpdateForm"
          >
            <v-row class="ma-0">
              <v-col cols="12" lg="9" class="order-2 order-lg-1">
                <v-row class="">
                  <v-col cols="12" lg="12" class="">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Event Name"
                      :rules="{ required: true }"
                      mode="lazy"
                    >
                      <v-text-field
                        name="Event Name"
                        label="Event Name"
                        class="form-control"
                        placeholder="Event Name"
                        required
                        outlined
                        :hide-details="!errors.length"
                        :error-messages="errors"
                        v-model="title"
                        :disabled="queryParms ? true : false"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="3" lg="3" class="">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Start Date"
                      :rules="{ required: true }"
                      mode="lazy"
                    >
                      <v-menu
                        v-model="isStartDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="startDateFormat"
                            label="Start Date"
                            class="form-control"
                            append-icon="mdi-calendar-range"
                            placeholder="DD/MM/YYYY"
                            required
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            :disabled="loading"
                            :hide-details="!errors.length"
                            :error-messages="errors"
                          ></v-text-field>
                        </template>
                        <!-- {{ isStartDate }} -->
                        <v-date-picker
                          color="#efce6a"
                          @input="isStartDate = false"
                          v-model="startDate"
                          @change="changeEndDate(startDate)"
                          :min="today"
                        ></v-date-picker>
                      </v-menu>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="3" lg="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Start time"
                      :rules="{ required: true }"
                      mode="lazy"
                    >
                      <v-menu
                        ref="menu"
                        v-model="IsStartItme"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="startTime"
                            label="Start time"
                            class="form-control"
                            append-icon="mdi-clock-time-four-outline"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            :hide-details="!errors.length"
                            :error-messages="errors"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="IsStartItme"
                          v-model="startTime"
                          format="ampm"
                          full-width
                          @change="changeTime(endDate, startTime)"
                          @click:minute="$refs.menu.save(startTime)"
                        ></v-time-picker>
                      </v-menu>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="3" lg="3" class="">
                    <validation-provider
                      v-slot="{ errors }"
                      name="End Date"
                      :rules="{ required: true }"
                      mode="lazy"
                    >
                      <v-menu
                        v-model="isEndDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="endDateFormat"
                            label="End Date"
                            class="form-control"
                            append-icon="mdi-calendar-range"
                            placeholder="DD/MM/YYYY"
                            required
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            :disabled="loading"
                            :hide-details="!errors.length"
                            :error-messages="errors"
                          ></v-text-field>
                        </template>

                        <v-date-picker
                          v-model="endDate"
                          color="#efce6a"
                          @input="isEndDate = false"
                          :min="startDate"
                          @change="changeTime(endDate, startTime)"
                        ></v-date-picker>
                      </v-menu>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="3" lg="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="End time"
                      :rules="{ required: true }"
                      mode="lazy"
                    >
                      <v-menu
                        ref="menu"
                        v-model="IsEndItme"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="endTime"
                            class="form-control"
                            label="End Time"
                            append-icon="mdi-clock-time-four-outline"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            :hide-details="!errors.length"
                            :error-messages="errors"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="IsEndItme"
                          v-model="endTime"
                          full-width
                          format="ampm"
                          @click:minute="$refs.menu.save(endTime)"
                          :min="getMinTime"
                        ></v-time-picker>
                      </v-menu>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="4" class="">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Hotel Name"
                      rules="required"
                      mode="lazy"
                    >
                      <v-autocomplete
                        v-model="hotelId"
                        name="Hotel Name"
                        :items="hotelDetail"
                        item-value="hotelId"
                        item-text="name"
                        outlined
                        dense
                        chips
                        small-chips
                        label="Hotel Name"
                        :hide-details="!errors.length"
                        :error-messages="errors"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- <v-col cols="12" sm="4" class="">
                <v-text-field
                  label="Location"
                  class="form-control"
                  placeholder="Location"
                  append-icon="mdi-map-marker"
                  required
                  outlined
                  disabled
                  hide-details
                ></v-text-field>
              </v-col> -->
                  <v-col cols="12" sm="4" class="">
                    <v-autocomplete
                      name="Nearby Hotel"
                      v-model="eventNearbyHotels"
                      :items="hotelDetail"
                      item-value="hotelId"
                      item-text="name"
                      outlined
                      dense
                      return-object
                      small-chips
                      label="Near By Hotels"
                      multiple
                      hide-details=""
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" class="">
                    <div class="d-flex flex-column flex-md-row">
                      <v-checkbox
                        v-model="isGiftAvailable"
                        hide-details
                        label=""
                        class="mt-1 mr-3 mb-3 mb-md-0"
                      ></v-checkbox>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Gift"
                        :rules="{ required: isGiftAvailable }"
                        mode="lazy"
                      >
                        <v-autocomplete
                          v-model="eventGifts"
                          :disabled="!isGiftAvailable"
                          :items="giftDetail"
                          item-value="giftId"
                          item-text="giftName"
                          return-object
                          outlined
                          dense
                          chips
                          small-chips
                          label="Gift Available"
                          multiple
                          :hide-details="!errors.length"
                          :error-messages="errors"
                        ></v-autocomplete>
                      </validation-provider>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4" class="">
                    <v-select
                      v-model="eventActivities"
                      :items="ActivityDetail"
                      item-text="name"
                      item-value="activityId"
                      return-object
                      outlined
                      dense
                      chips
                      small-chips
                      label="Activity"
                      multiple
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4" lg="4" class="">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Registration CutOff"
                      :rules="{ required: true }"
                      mode="lazy"
                    >
                      <v-menu
                        v-model="isRegisterDateCutOff"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="registrationCutoff"
                            label="Registraion Cutoff"
                            class="form-control"
                            append-icon="mdi-calendar-range"
                            placeholder="DD/MM/YYYY"
                            required
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            :disabled="loading"
                            :hide-details="!errors.length"
                            :error-messages="errors"
                          ></v-text-field>
                        </template>
                        <!-- {{ isStartDate }} -->
                        <v-date-picker
                          color="#efce6a"
                          @input="isRegisterDateCutOff = false"
                          v-model="cutoffDate"
                          :max="startDate"
                        ></v-date-picker>
                      </v-menu>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="d-flex flex-wrap">
                      <v-checkbox
                        v-model="isMultiselected"
                        hide-details
                        label="Multiple Gift Select"
                        class="mt-1 mr-3"
                      ></v-checkbox></div
                  ></v-col>
                  <v-col cols="12">
                    <div class="d-flex flex-wrap">
                      <v-checkbox
                        v-model="isActive"
                        hide-details
                        label="Active"
                        class="mt-1 mr-5"
                      ></v-checkbox>

                      <v-radio-group v-model="isPublic" class="vRadioButton">
                        <v-radio
                          hide-details
                          label="Public"
                          class="mt-1 mr-5"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          hide-details
                          label="Private"
                          class="mr-5 mb-0"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>

                      <v-checkbox
                        v-model="isCodeRequired"
                        hide-details
                        label="Access Code Required?"
                        class="mt-1 mr-3"
                      ></v-checkbox>
                    </div>
                  </v-col>

                  <v-col cols="12" class="">
                    <div class="text-h6">Itinerary</div>
                    <v-row class="my-0">
                      <v-col cols="12" class="">
                        <div
                          class="d-flex flex-column flex-md-row mb-2"
                          v-for="(iti, index) in eventItineraries"
                          :key="index"
                        >
                          <v-autocomplete
                            v-model="iti.itineraryId"
                            :items="ItineraryDetail"
                            item-text="name"
                            item-value="itineraryId"
                            outlined
                            dense
                            label="Itinerary"
                          ></v-autocomplete>
                          <validation-provider
                            class=""
                            v-slot="{ errors }"
                            slim
                            name="Event Itinerary"
                            :rules="{
                              required:
                                iti.itineraryId &&
                                !iti.itineraryFileName &&
                                isitinerary
                                  ? true
                                  : false,
                            }"
                            mode="lazy"
                          >
                            <v-file-input
                              :disabled="!iti.itineraryId"
                              v-if="!iti.itineraryFileName"
                              id="inputFile"
                              counter
                              label="Browse"
                              placeholder="Browse"
                              class="ml-md-3 mt-3 mt-md-0"
                              @change="
                                previewPdf(iti.itineraryId, index, $event)
                              "
                              accept="application/pdf"
                              outlined
                              :show-size="1000"
                              :hide-details="!errors.length"
                              :error-messages="errors"
                              :clearable="false"
                            >
                            </v-file-input>
                          </validation-provider>

                          <v-text-field
                            v-if="iti.itineraryFileName"
                            name=""
                            label=""
                            class="form-control ml-2 mb-2"
                            placeholder=""
                            required
                            readonly
                            prepend-icon="mdi-attachment"
                            outlined
                            v-model="iti.itineraryFileName"
                            for="itin"
                          ></v-text-field>

                          <div class="d-flex ml-3">
                            <v-btn
                              elevation="2"
                              icon
                              rounded
                              class="mr-3"
                              style="background-color: #f0ce6a"
                              @click="addItinerary"
                              :disabled="eventItineraries.length >= 5"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                              elevation="2"
                              icon
                              rounded
                              style="background-color: #f0ce6a"
                              @click="removeItinerary(index)"
                            >
                              <v-icon dense>mdi-minus</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="">
                    <v-checkbox
                      v-model="isGuestSpeaker"
                      hide-details
                      label="Guest Speaker"
                      class="mt-1 mr-3"
                    ></v-checkbox>

                    <v-row class="my-0" v-if="isGuestSpeaker">
                      <v-col cols="12" class="">
                        <div
                          class="d-flex flex-column flex-md-row mb-2"
                          style="align-items: center"
                          v-for="(guest, guestIndex) in eventGuestSpeakers"
                          :key="`guest${guestIndex}`"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Guest Name"
                            :rules="{ required: isGuestSpeaker }"
                            mode="lazy"
                            slim
                          >
                            <div class="guestNameHeader">
                              <v-text-field
                                outlined
                                dense
                                label="Guest Name"
                                v-model="guest.speakerName"
                                :hide-details="!errors.length"
                                :error-messages="errors"
                              ></v-text-field>
                            </div>
                          </validation-provider>
                          <!-- <v-text-field
                              v-else
                              name=""
                              class="form-control ml-2 mb-2"
                              label="Profile Picture"
                              required
                              readonly
                              prepend-icon="mdi-attachment"
                              outlined
                              hide-details
                              v-model="guest.profileFileName"
                            ></v-text-field> -->{{ inpuvalue }}
                          <div class="gustprofileClass">
                            <div class="profile-pic-div-guest">
                              <img
                                :src="
                                  guest.s3FileUrl
                                    ? guest.s3FileUrl
                                    : `data:image/jpeg;base64,${guest.profileImage}`
                                "
                                id="photo"
                              />

                              <input
                                type="file"
                                :id="`file${guestIndex}`"
                                class="fileInput"
                                @change="
                                  changeSpeakerProfile(
                                    $event.target.files[0],
                                    guestIndex
                                  )
                                "
                                accept="image/*"
                              />

                              <label :for="`file${guestIndex}`" id="uploadBtn"
                                >Profile Picture</label
                              >
                            </div>
                          </div>
                          <div class="guestNameDoc">
                            <v-file-input
                              v-if="!guest.eventSpeakerMedias"
                              id="inputFile"
                              counter
                              label="Documents or videos"
                              placeholder="Relate Information"
                              class="ml-md-3 mt-3 mt-md-0"
                              @change="guestSpeakerUpload(guestIndex, $event)"
                              accept="image/*,video/*,application/pdf"
                              outlined
                              multiple
                              :clearable="false"
                            >
                            </v-file-input>
                            <v-text-field
                              v-if="guest.eventSpeakerMedias"
                              name=""
                              label=""
                              class="form-control ml-2 mb-2"
                              placeholder=""
                              required
                              readonly
                              prepend-icon="mdi-attachment"
                              outlined
                              :value="getGuestMedia(guest)"
                              for="itin"
                            ></v-text-field>
                          </div>

                          <!-- {{ guest.eventSpeakerMedias }} -->
                          <div class="d-flex ml-3">
                            <v-btn
                              elevation="2"
                              icon
                              rounded
                              class="mr-3"
                              style="background-color: #f0ce6a"
                              @click="addGuestSpeaker"
                              :disabled="eventGuestSpeakers.length >= 5"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                              elevation="2"
                              icon
                              rounded
                              style="background-color: #f0ce6a"
                              @click="removeGuestSpeaker(guestIndex)"
                            >
                              <v-icon dense>mdi-minus</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="">
                    <v-textarea
                      label="Event Description"
                      class="form-control"
                      placeholder="Event Description"
                      required
                      outlined
                      v-model="description"
                      counter
                      :rules="rules"
                    ></v-textarea>
                  </v-col>
                  <!-- </validation-provider> -->
                </v-row>
              </v-col>
              <v-col cols="12" lg="3" class="order-1 order-lg-2">
                <!-- <div class="d-flex flex-column">
            <div class="bg-img-box">
              <img src="../../assets/img1.jpg" alt="" />
            </div>
            <v-file-input
              v-model="files"
              counter
              label="Background"
              multiple
              placeholder="Browse"
              class="mt-md-5"
              outlined
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 2" dark label small>
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="text-overline grey--text text--darken-3 mx-2"
                >
                  +{{ files.length - 2 }} File(s)
                </span>
              </template>
            </v-file-input>
          </div> -->

                <div class="profile-pic-div">
                  <img :src="preview" id="photo" />

                  <input
                    type="file"
                    id="file"
                    class="fileInput"
                    @change="previewImage($event)"
                    accept="image/*"
                  />

                  <label for="file" id="uploadBtn">Choose Background</label>
                </div>
              </v-col>
              <v-col cols="12" class="text-right order-3">
                <v-btn
                  class="right-btn"
                  style="background-color: #4c4c4c"
                  elevation="0"
                  @click="$router.push({ name: 'Events' })"
                  >cancel </v-btn
                >&nbsp;
                <v-btn
                  type="submit"
                  v-if="!queryParms"
                  class="right-btn"
                  elevation="0"
                  :loading="loading"
                >
                  ADD
                </v-btn>
                <v-btn
                  v-else
                  type="submit"
                  class="right-btn"
                  elevation="0"
                  :loading="loading"
                >
                  Update
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-card>
      <progress-circular :dialog="loading" />
      <!-- <add-guest-speaker
        :dialog="dialogUpdateGuestSpeakers"
        @disableDialog="closeModal"
        @addGuestSpeaker="addGuestSpeaker($event)"
      /> -->
    </div>
  </master-layout>
</template>
<script>
import HotelService from "../../services/HotelService";
import GiftService from "../../services/GiftService";
import ActivityService from "../../services/ActivityService";
import ItineraryService from "../../services/ItineraryService";
import EventService from "../../services/EventService";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// import moment from "moment";
import {
  toBase64,
  dateFormat,
  tConvert,
  convertInto12Hr,
  getDateFormat,
  checkFormatType,
} from "../../utils/helpers";

import ProgressCircular from "../../components/ProgressCircular.vue";
// import AddGuestSpeaker from "../../components/Modals/AddGuestSpeaker.vue";
// import moment from "moment";
export default {
  computed: {
    startDateFormat() {
      return dateFormat(this.startDate, "MM-dd-yyyy");
    },
    registrationCutoff() {
      return dateFormat(this.cutoffDate, "MM-dd-yyyy");
    },
    endDateFormat() {
      return dateFormat(this.endDate, "MM-dd-yyyy");
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ProgressCircular,
    // AddGuestSpeaker,
  },
  data: () => ({
    isPublic: true,
    cutoffDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    isitinerary: true,
    isGuestSpeaker: false,
    rules: [(val) => val.length <= 1000 || "Max 1000 characters"],
    eventGuestSpeakers: [
      {
        id: 0,
        speakerName: "",
        images: [],
        videos: [],
        pdfs: [],
      },
    ],
    drawer: null,
    imageInserted: "",
    dialog: false,
    dialogUpdateGuestSpeakers: false,
    isStartDate: false,
    isEndDate: false,
    isRegisterDateCutOff: false,
    startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    hotelDetail: [],
    ActivityDetail: [],
    giftDetail: [],
    ItineraryDetail: [],
    id: 0,
    title: "",
    description: "",
    hotelId: "",
    IsStartItme: false,
    IsEndItme: false,
    startTime: "12:00",
    endTime: "12:00",
    getMinTime: "12:00",
    preview: "",
    backgroundOrigionalFileName: "",
    backgroundImage: "",
    isActive: true,
    eventNearbyHotels: [],
    eventGifts: [],
    eventActivities: [],
    isCodeRequired: false,
    eventItineraries: [
      {
        id: 0,
        isActive: false,
        itineraryId: 0,
        itineraryFile: "NA",
        origionalFileName: "",
      },
    ],
    eventItineraryId: [],
    isGiftAvailable: false,
    isCompleted: false,
    hotelid: 0,
    queryParms: "",
    previousHotelList: [],
    previousGiftList: [],
    previousActivityList: [],
    previousItineraryList: [],
    previousGuestSpeakerList: [],
    itineraryId: "",
    eventImage: "",
    loading: false,
    itineraryLength: 1,
    isMultiselected: false,
    backgroundVirtualFileName: "",
    inpuvalue: "",
  }),
  async mounted() {
    this.loading = true;
    this.queryParms = this.$route.query.eventId;
    if (this.queryParms) {
      const res = await EventService.getEventDetail(this.queryParms);
      let response = res.data.data;

      this.startTime = convertInto12Hr(getDateFormat(response.startDate));
      this.endTime = convertInto12Hr(getDateFormat(response.endDate));
      // this.isStartDate = false;
      this.id = response.id;
      this.title = response.title;
      this.description = response.description;
      this.hotelId = response.hotelId;

      this.backgroundOrigionalFileName = response.backgroundFileName;
      this.backgroundImage = response.s3FileUrl;
      // (this.preview = "../../assets/images/blankImage.jpg"),
      this.isActive = response.isActive;
      // this.isGiftAvailable = response.isGiftAvailable;
      // this.isCompleted = response.isCompleted;
      this.isGiftAvailable = response.isGiftAvailable;
      this.isMultiselected = response.isMultiGift;
      this.isCodeRequired = response.isCodeRequired;
      this.eventNearbyHotels = JSON.parse(
        JSON.stringify(response.eventNearbyHotels)
      );
      this.previousHotelList = JSON.parse(
        JSON.stringify(response.eventNearbyHotels)
      );
      this.eventGifts = JSON.parse(JSON.stringify(response.eventGifts));
      this.previousGiftList = JSON.parse(JSON.stringify(response.eventGifts));
      this.eventActivities = JSON.parse(
        JSON.stringify(response.eventActivities)
      );
      this.previousActivityList = JSON.parse(
        JSON.stringify(response.eventActivities)
      );
      this.eventItineraries = JSON.parse(
        JSON.stringify(response.eventItineraries)
      );
      this.previousItineraryList = JSON.parse(
        JSON.stringify(response.eventItineraries)
      );
      // this.eventItinerary = response.eventItinerary;
      this.isPublic = response.isPublic;
      this.hotelId = response.hotelId;
      this.isGuestSpeaker = response.isGuestSpeakerAvailable;
      this.eventItineraries =
        response.eventItineraries.length < 1
          ? [
              {
                id: 0,
                isActive: false,
                itineraryId: 0,
                itineraryFile: "NA",
                origionalFileName: "",
                virtualFileName: "",
              },
            ]
          : response.eventItineraries;
      this.previousGuestSpeakerList = JSON.parse(
        JSON.stringify(response.eventGuestSpeakers)
      );
      this.eventGuestSpeakers =
        response.eventGuestSpeakers.length === 0
          ? [
              {
                id: 0,
                isActive: true,
                speakerName: "",
                images: [],
                videos: [],
                pdfs: [],
              },
            ]
          : JSON.parse(JSON.stringify(response.eventGuestSpeakers));
      this.preview = response.s3FileUrl;
      this.backgroundVirtualFileName = response.backgroundVirtualFileName;
      this.loading = false;
      // this.itineraryLength = 2;
      // for (let i = 0; i < this.itineraryLength; i++) {
      //   this.eventItineraryId[i] = response.eventActivities[i].itineraryId;
      // }

      let stime = response.startDate.split(" ")[0];
      this.startDate = dateFormat(stime.replace(/-/g, "/"), "yyyy-MM-dd");
      this.endDate = dateFormat(
        response.endDate.split(" ")[0].replace(/-/g, "/"),
        "yyyy-MM-dd"
      );
      this.cutoffDate = dateFormat(
        response.registrationEndDate.split(" ")[0].replace(/-/g, "/"),
        "yyyy-MM-dd"
      );
    }
    this.getHotelList();
    this.getGiftList();
    this.getActivityList();
    this.getItineraryList();

    this.loading = false;
  },

  methods: {
    removeItinerary(index) {
      // const indx = this.eventItineraries.findIndex((item,index) => index == index);
      this.eventItineraries.splice(index, 1);
      if (this.eventItineraries.length < 1) {
        this.eventItineraries.push({
          id: 0,
          isActive: true,
          itineraryId: 0,
          itineraryFile: "NA",
          origionalFileName: "",
          virtualFileName: "",
        });
      }
    },
    removeGuestSpeaker(index) {
      // const indx = this.eventItineraries.findIndex((item,index) => index == index);
      this.eventGuestSpeakers.splice(index, 1);
      if (this.eventGuestSpeakers.length < 1) {
        this.eventGuestSpeakers.push({
          id: 0,
          isActive: true,
          speakerName: "",
          images: [],
          videos: [],
          pdfs: [],
        });
      }
    },

    async getHotelList() {
      try {
        const response = await HotelService.hotelList(true);

        this.hotelDetail = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getGiftList() {
      try {
        const response = await GiftService.giftList(true);

        this.giftDetail = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getActivityList() {
      try {
        const response = await ActivityService.activityList(true);

        this.ActivityDetail = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getItineraryList() {
      try {
        const response = await ItineraryService.itineraryList(true);

        this.ItineraryDetail = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async addNewEvent() {
      this.loading = true;
      if (this.backgroundImage === "") {
        this.$store.dispatch(
          "notifications/setNotification",
          {
            text: "Backround image is required",
            type: "error",
          },
          { root: true }
        );
        this.loading = false;
      } else {
        try {
          const eventNearbyHotels = this.eventNearbyHotels.map((item) => {
            return { id: 0, hotelId: item.hotelId, isActive: true };
          });
          const eventGifts = this.eventGifts.map((item) => {
            return { id: 0, giftId: item.giftId, isActive: true };
          });
          const eventActivities = this.eventActivities.map((item, index) => {
            return {
              id: 0,
              activityId: item.activityId,
              isActive: true,
              orderNumber: index + 1,
            };
          });
          const eventGuestSpeaker = this.eventGuestSpeakers.map((item) => {
            return {
              id: 0,
              orderNumber: 0,
              isActive: true,
              ...item,
            };
          });
          const indexOfguest = this.eventGuestSpeakers.findIndex(
            (item) => item.speakerName === ""
          );
          if (indexOfguest !== -1) {
            this.eventGuestSpeakers.splice(indexOfguest, 1);
          }

          const indexOfObj = this.eventItineraries.findIndex(
            (item) => item.itineraryFile === "NA"
          );
          if (indexOfObj !== -1) {
            this.eventItineraries.splice(indexOfObj, 1);
          }

          // const stime = tConvert(this.startTime);
          // const etime = tConvert(this.endTime);

          const response = await EventService.addEvent({
            id: 0,
            title: this.title,
            description: this.description,
            hotelId: this.hotelId,
            startDate:
              this.startDateFormat +
              " " +
              tConvert(this.startTime).split(":").join(" "),
            endDate:
              this.endDateFormat +
              " " +
              tConvert(this.endTime).split(":").join(" "),
            backgroundOrigionalFileName: this.backgroundOrigionalFileName,
            backgroundImage: this.backgroundImage,
            isActive: this.isActive,
            isPublic: this.isPublic,
            isGiftAvailable: this.isGiftAvailable,
            // isCompleted: this.isCompleted,
            eventNearbyHotels: eventNearbyHotels,
            eventGifts: eventGifts,
            eventActivities: eventActivities,
            eventItinerary: this.eventItineraries,
            isMultiGift: this.isMultiselected,
            isCodeRequired: this.isCodeRequired,
            isGuestSpeakerAvailable: this.isGuestSpeaker || false,
            registrationEndDate:
              this.registrationCutoff + " 12 " + "00 " + "am",
            eventGuestSpeakers: eventGuestSpeaker,
            backgroundVirtualFileName: this.backgroundVirtualFileName || "",
          });
          this.ItineraryDetails = response.data.data;
          if (response.data.errorMessage == "") {
            this.$store.dispatch(
              "notifications/setNotification",
              {
                text: "Event Entered Successfully",
                type: "success",
              },
              { root: true },
              (this.loading = false),
              this.$router.push({
                name: "Events",
              })
            );
          } else {
            this.$store.dispatch(
              "notifications/setNotification",
              {
                text: response.data.errorMessage,

                type: "error",
              },
              { root: true }
            );
            this.loading = false;
          }
        } catch (error) {
          console.log(error);
          this.loading = false;
        }
      }
    },
    previewImage(event) {
      var input = event.target;
      // console.log(input.files[0].type);
      let isOk = checkFormatType(
        input.files[0].type,
        input.files[0].size,
        "image"
      );

      if (input.files && isOk === "ok") {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
          this.backgroundImage = e.target.result.split("base64,")[1];
        };
        reader.readAsDataURL(input.files[0]);

        // this.giftImageData = reader.result.split("base64,")[1];
        this.backgroundOrigionalFileName = input.files[0].name;
      }
    },
    async previewPdf(eventItineraryId, index, selectedFile) {
      let isOk = checkFormatType(selectedFile.type, selectedFile.size, "pdf");
      if (isOk === "ok") {
        const result = await toBase64(selectedFile);
        let itinerary = {
          id: 0,
          itineraryId: eventItineraryId,
          origionalFileName: selectedFile.name,
          itineraryFile: result.split("base64,")[1],
          isActive: true,
          virtualFileName: "",
        };
        if (itinerary.origionalFileName) {
          this.isitinerary = false;
        }

        this.eventItineraries[index] = itinerary;
      }
    },
    async guestSpeakerUpload(index, event) {
      var input = event;
      var result = [];
      let images = [];
      let videos = [];
      let pdfs = [];
      if (this.eventGuestSpeakers[index].id != 0) {
        this.eventGuestSpeakers[index] = {
          images,
          videos,
          pdfs,
          id: this.eventGuestSpeakers[index].id,
          isActive: true,
          orderNumber: 0,
          fileType: 0,
          profileFileName: this.eventGuestSpeakers[index].profileFileName,
          speakerName: this.eventGuestSpeakers[index].speakerName,
          virtualFileName: this.eventGuestSpeakers[index].virtualFileName,
        };
      } else {
        this.eventGuestSpeakers[index] = {
          images,
          videos,
          pdfs,
          ...this.eventGuestSpeakers[index],
        };
      }
      for (let i = 0; i < input.length; i++) {
        result[i] = await toBase64(input[i]);
        if (
          [
            "image/jpeg",
            "image/jpg",
            "image/png",
            "image/gif",
            "image/bmp",
          ].includes(input[i].type)
        )
          this.eventGuestSpeakers[index].images[i] = {
            id: 0,
            virtualFileName: "",
            origionalFileName: input[i].name,
            eventMedia: result[i].split("base64,")[1],
            fileType: 0,
            isActive: true,
          };
        else if (
          [
            "video/mpeg",
            "video/mpg",
            "video/avi",
            "video/wmv",
            "video/mov",
            "video/rm",
            "video/ram",
            "video/swf",
            "video/flv",
            "video/ogg",
            "video/webm",
            "video/mp4",
          ].includes(input[i].type)
        ) {
          this.eventGuestSpeakers[index].videos[i] = {
            id: 0,
            virtualFileName: "",
            origionalFileName: input[i].name,
            eventMedia: result[i].split("base64,")[1],
            fileType: 0,
            isActive: true,
          };
        } else if (["application/pdf"].includes(input[i].type)) {
          this.eventGuestSpeakers[index].pdfs[i] = {
            id: 0,
            virtualFileName: "",
            origionalFileName: input[i].name,
            eventMedia: result[i].split("base64,")[1],
            fileType: 0,
            isActive: true,
          };
        }
      }

      (this.eventGuestSpeakers[index].images = this.eventGuestSpeakers[
        index
      ].images?.filter((element) => {
        if (Object.keys(element).length !== 0) {
          return true;
        }

        return false;
      })),
        (this.eventGuestSpeakers[index].videos = this.eventGuestSpeakers[
          index
        ].videos?.filter((element) => {
          if (Object.keys(element).length !== 0) {
            return true;
          }

          return false;
        }));
      this.eventGuestSpeakers[index].pdfs = this.eventGuestSpeakers[
        index
      ].pdfs?.filter((element) => {
        if (Object.keys(element).length !== 0) {
          return true;
        }

        return false;
      });
    },
    async updateNewEvent() {
      const hotelEvents = this.eventNearbyHotels.map((hotel) => {
        return {
          id: hotel.id || 0,
          hotelId: hotel.hotelId,
          isActive: hotel.isActive,
        };
      });
      this.previousHotelList.forEach((hotel) => {
        const hotelExist = this.eventNearbyHotels.findIndex((eventHotel) => {
          return eventHotel.hotelId === hotel.hotelId;
        });
        if (hotelExist === -1) {
          hotelEvents.push({
            id: hotel.id,
            hotelId: hotel.hotelId,
            isActive: false,
          });
        }
      });
      if (this.eventGifts) {
        var giftEvents = this.eventGifts.map((gift) => {
          return {
            id: gift.id || 0,
            giftId: gift.giftId,
            isActive: gift.isActive,
          };
        });
        if (this.previousGiftList) {
          this.previousGiftList.forEach((gift) => {
            const giftExist = this.eventGifts.findIndex((eventGift) => {
              return eventGift.giftId === gift.giftId;
            });
            if (giftExist === -1) {
              giftEvents.push({
                id: gift.id,
                giftId: gift.giftId,
                isActive: false,
              });
            }
          });
        }
      }
      if (this.eventActivities) {
        var activityEvents = this.eventActivities.map((activity, index) => {
          return {
            id: activity.id || 0,
            activityId: activity.activityId,
            isActive: activity.isActive,
            orderNumber: activity.orderNumber || index + 1,
          };
        });
        if (this.previousActivityList) {
          this.previousActivityList.forEach((activity) => {
            const activityExist = this.eventActivities.findIndex(
              (eventActivity) => {
                return eventActivity.activityId === activity.activityId;
              }
            );
            if (activityExist === -1) {
              activityEvents.push({
                id: activity.id,
                activityId: activity.activityId,
                isActive: false,
                orderNumber: activity.orderNumber,
              });
            }
          });
        }
      }
      if (this.eventItineraries.length !== 0) {
        var itineraryEvents = this.eventItineraries.map((itinerary) => {
          return {
            id: itinerary.id || 0,
            isActive: true,
            itineraryFile: itinerary.itineraryFile || "",
            itineraryId: itinerary.itineraryId,
            origionalFileName:
              itinerary.origionalFileName || itinerary.itineraryFileName || "",
            virtualFileName: itinerary.itineraryVirtualFileName || "",
          };
        });
        if (this.previousItineraryList) {
          this.previousItineraryList.forEach((itinerary) => {
            const itineraryExist = this.eventItineraries.findIndex(
              (eventItinerary) => {
                return eventItinerary.itineraryId === itinerary.itineraryId;
              }
            );
            if (itineraryExist === -1) {
              itineraryEvents.push({
                id: itinerary.id,
                isActive: false,
                itineraryFile: "",
                itineraryId: itinerary.itineraryId,
                virtualFileName: itinerary.itineraryVirtualFileName || "",
                origionalFileName: itinerary.itineraryFileName || "",
              });
            }
          });
        }
      }
      const indexOfObj = itineraryEvents.findIndex(
        (item) => item.itineraryFile === "NA"
      );
      if (indexOfObj !== -1) {
        itineraryEvents.splice(indexOfObj, 1);
      }
      if (this.eventGuestSpeakers.length !== 0) {
        var eventGuest = this.eventGuestSpeakers.map((guestItem) => {
          return guestItem.id !== 0
            ? {
                id: guestItem.id || 0,
                orderNumber: 0,
                isActive: true,

                speakerName: guestItem.speakerName,
                profileVirtualFileName:
                  guestItem.virtualFileName ||
                  guestItem.profileVirtualFileName ||
                  "",
                profileOrigionalFileName:
                  guestItem.profileFileName ||
                  guestItem.profileOrigionalFileName,
                profileImage: guestItem.profileImage || "",

                images: guestItem.eventSpeakerMedias
                  ? guestItem?.eventSpeakerMedias?.eventPhotos?.map((image) => {
                      return {
                        id: image.id,
                        virtualFileName: image.virtualFileName,
                        origionalFileName: image.originalFileName,
                        eventMedia: "",
                        fileType: 0,
                        isActive: image.isActive,
                      };
                    })
                  : guestItem.images,
                videos: guestItem.eventSpeakerMedias
                  ? guestItem.eventSpeakerMedias?.eventVideos?.map((item) => {
                      return {
                        id: item.id,
                        virtualFileName: item.virtualFileName,
                        origionalFileName: item.originalFileName,
                        eventMedia: "",
                        fileType: 0,
                        isActive: true,
                      };
                    })
                  : guestItem.videos,
                pdfs: guestItem.eventSpeakerMedias
                  ? guestItem.eventSpeakerMedias?.eventPdf?.map((item) => {
                      return {
                        id: item.id,
                        virtualFileName: item.virtualFileName,
                        origionalFileName: item.originalFileName,
                        eventMedia: "",
                        fileType: 0,
                        isActive: true,
                      };
                    })
                  : guestItem.pdfs,

                // ...guestItem,
              }
            : {
                ...guestItem,
              };
        });
        if (this.previousGuestSpeakerList) {
          this.previousGuestSpeakerList.forEach((item) => {
            const guest = this.eventGuestSpeakers.findIndex(
              (guestSpeakerList) => {
                return guestSpeakerList.id === item.id;
              }
            );
            if (guest === -1) {
              eventGuest.push({
                id: item.id,
                orderNumber: 0,
                isActive: false,
                speakerName: item.speakerName,
                profileVirtualFileName: item.virtualFileName,
                profileOrigionalFileName: item.profileFileName,
                profileImage: "",
                images: item.eventSpeakerMedias?.eventPhotos.map((item) => {
                  return {
                    id: item.id,
                    virtualFileName: item.virtualFileName,
                    origionalFileName: item.originalFileName,
                    eventMedia: "",
                    fileType: 0,
                    isActive: false,
                  };
                }),
                videos: item.eventSpeakerMedias?.eventVideos.map((item) => {
                  return {
                    id: item.id,
                    virtualFileName: item.virtualFileName,
                    origionalFileName: item.originalFileName,
                    eventMedia: "",
                    fileType: 0,
                    isActive: false,
                  };
                }),
                pdfs: item.eventSpeakerMedias?.eventPdf.map((item) => {
                  return {
                    id: item.id,
                    virtualFileName: item.virtualFileName,
                    origionalFileName: item.originalFileName,
                    eventMedia: "",
                    fileType: 0,
                    isActive: false,
                  };
                }),
              });
            }
          });
        }
      }
      const indexOfguest = eventGuest.findIndex(
        (item) => item.speakerName === ""
      );
      if (indexOfguest !== -1) {
        eventGuest.splice(indexOfguest, 1);
      }
      if (this.backgroundImage === "") {
        this.$store.dispatch(
          "notifications/setNotification",
          {
            text: "Backround image is required",
            type: "error",
          },
          { root: true }
        );
        this.loading = false;
      } else {
        try {
          this.loading = true;
          const response = await EventService.updateEvent({
            id: this.id,
            title: this.title,
            description: this.description,
            hotelId: this.hotelId,
            startDate:
              this.startDateFormat +
              " " +
              tConvert(this.startTime).split(":").join(" "),
            endDate:
              this.endDateFormat +
              " " +
              tConvert(this.endTime).split(":").join(" "),
            backgroundOrigionalFileName: this.backgroundOrigionalFileName || "",
            backgroundImage: this.backgroundImage.includes("http")
              ? ""
              : this.backgroundImage,
            isActive: this.isActive,
            isPublic: this.isPublic,
            isGiftAvailable: this.isGiftAvailable,
            // isCompleted: this.isCompleted,
            eventNearbyHotels: hotelEvents,
            eventGifts: giftEvents || null,
            eventActivities: activityEvents || [],
            eventItinerary: itineraryEvents || [],
            isMultiGift: this.isMultiselected,
            isCodeRequired: this.isCodeRequired,
            isGuestSpeakerAvailable: this.isGuestSpeaker || false,
            registrationEndDate:
              this.registrationCutoff + " 12 " + "00 " + "AM",
            eventGuestSpeakers: eventGuest,
            backgroundVirtualFileName: this.backgroundImage.includes("http")
              ? this.backgroundVirtualFileName
              : "",
          });
          if (response.data.errorMessage == "") {
            this.$store.dispatch(
              "notifications/setNotification",
              {
                text: response.data.data.message,
                type: "success",
              },
              { root: true },

              this.$router.push({
                name: "Events",
              })
            );
            const msg = response.data.data.notifications.toString();
            await EventService.pushNotificationService({
              eventId: this.id,
              title: "Changes in Event",
              message: msg,
            });
          } else {
            this.$store.dispatch(
              "notifications/setNotification",
              {
                text: response.data.errorMessage,
                type: "error",
              },
              { root: true }
            );
          }
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.loading = false;
        }
      }
    },
    // openModal(event) {
    //   if (event) this.dialogUpdateGuestSpeakers = true;
    //   else if (!event) {
    //     console.log(event);
    //   }
    // },
    // closeModal(event) {
    //   this.dialogUpdateGuestSpeakers = event.value;
    //   if (event.status === "close") {
    //     this.isGuestSpeaker = false;
    //   }
    // },
    addItinerary() {
      this.eventItineraries.push({
        id: 0,
        isActive: false,
        itineraryId: 0,
        itineraryFile: "NA",
        origionalFileName: "",
      });
    },
    addGuestSpeaker() {
      this.eventGuestSpeakers.push({
        id: 0,
        speakerName: "",
        orderNumber: 0,
        isActive: true,
        profileVirtualFileName: "",
        profileOrigionalFileName: "",
        profileImage: "",
        images: [],
        videos: [],
        pdfs: [],
      });
    },
    async changeSpeakerProfile(selectedFile, index) {
      let isOk = checkFormatType(selectedFile.type, selectedFile.size, "image");
      if (isOk === "ok") {
        const result = await toBase64(selectedFile);
        let profile = {
          profileVirtualFileName: "",
          profileOrigionalFileName: selectedFile.name,
          profileImage: result.split("base64,")[1],
        };

        let payload = {};
        if (this.queryParms) {
          payload = {
            eventSpeakerMedias:
              this.eventGuestSpeakers[index].eventSpeakerMedias,
          };
        } else {
          payload = {
            ...this.eventGuestSpeakers[index],
          };
        }

        this.eventGuestSpeakers[index] = {
          ...payload,
          ...profile,
          id: this.eventGuestSpeakers[index].id,
          speakerName: this.eventGuestSpeakers[index].speakerName,
          orderNumber: this.eventGuestSpeakers[index].orderNumber || 0,
          isActive: this.eventGuestSpeakers[index].isActive || true,
        };
      }
    },
    changeTime(date, time) {
      if (this.startDate == date) {
        console.log(date, time);
        this.getMinTime = time;
        this.endTime = time;
      } else {
        this.endTime = "12:00";
        this.getMinTime = "";
      }
    },
    changeEndDate(startDate) {
      if (startDate > this.endDate) {
        this.endDate = startDate;
      }
    },
    getGuestMedia(obj) {
      return `${
        obj.eventSpeakerMedias.eventPdf.length +
        obj.eventSpeakerMedias.eventPhotos.length +
        obj.eventSpeakerMedias.eventVideos.length
      } files`;
    },
  },
};
</script>
