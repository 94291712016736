import http from "./http-client";

class HotelService {
  addHotel(payload) {
    return http.post("/Hotel/Add", payload);
  }
  getHotelDetail(payload) {
    return http.get("/Hotel/Details", payload);
  }
  updateHotel(payload) {
    return http.put("/Hotel/Update", payload);
  }
  hotelList(payload) {
    if (!payload) {
      return http.get(`/Hotel/List`);
    } else {
      return http.get(`/Hotel/List?isActive=${payload}`);
    }
  }
}

export default new HotelService();
