import http from "./http-client";

class ItineraryService {
  getItineraryDetail(payload) {
    return http.get("/Itinerary/Details", payload);
  }
  updateItinerary(payload) {
    return http.put("/Itinerary/Update", payload);
  }
  itineraryList(payload) {
    if (!payload) {
      return http.get(`/Itinerary/List`);
    } else {
      return http.get(`/Itinerary/List?isActive=${payload}`);
    }
  }
  addItinerary(payload) {
    return http.post("/Itinerary/Add", payload);
  }
}

export default new ItineraryService();
