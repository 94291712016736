import http from "./http-client";

class GiftService {
  getGiftDetail(payload) {
    return http.get("/Gift/Details", payload);
  }
  updateGift(payload) {
    return http.put("/Gift/Update", payload);
  }
  giftList(payload) {
    if (!payload) {
      return http.get(`/Gift/List`);
    } else {
      return http.get(`/Gift/List?isActive=${payload}`);
    }
  }
  addGift(payload) {
    return http.post("/Gift/Add", payload);
  }
}

export default new GiftService();
